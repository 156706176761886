import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Fade from 'react-reveal/Fade';

const FeatureOne = () => {
  return (
    <> 
      <Fade bottom >
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <SectionTitle
                  title='Our Core Values'
                  description='At iGeeksNG, we have five pillars we believe are key to the success of our business and of our client experience.'
                  leftAlign
                />
                <ul className='list-unstyled mb-0'>
                  <li className='d-flex align-items-start mb-4'>
                    <div style={{background:"#ffb116"}} className='icon-box rounded me-4'>
                      <i className='fa-solid fa-badge-check text-black'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Quality</h3>
                      <p>
                      We place highest priority on delivering  digital products with best user experience irrespective of the product stage (MVP, Growing or Matured).
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div style={{background:"#ffb116"}} className='icon-box rounded me-4'>
                      <i className='fa-solid fa-people-carry-box text-black'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Strategic partnerships</h3>
                      <p>
                      Our partnerships last decades. Our clients remain with us not only for world-class software but for our dedicated-teams model.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div style={{background:"#ffb116"}} className='icon-box rounded me-4'>
                      <i className='fas fa-bezier-curve text-black'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>No limits</h3>
                      <p>
                      We are creative minds with "no box" mindsets. 
You dream of digital product(s), we make it happen...
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div style={{background:"#ffb116"}} className='icon-box rounded me-4'>
                      <i className='fa-solid fa-certificate text-black'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Impact</h3>
                      <p>
                      We are committed to designing and developing digital products that make solves the pain points of human being and the planet earth.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4 mb-lg-0'>
                    <div style={{background:"#ffb116"}} className='icon-box rounded me-4'>
                      <i className='fa-solid fa-globe text-black'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>Velocity</h3>
                      <p>
                      Size of a project does not impede how fast we deliver on projects. We always work with our partners/clients in bringing their projects to the market fast using agile methodology among other frameworks
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/core-value1.png'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      </Fade>
    </>
  );
};

export default FeatureOne;
