import Routes from './routers/Routes';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';


const App = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
