import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom';
import Clients from '../Clients';

const WhatWeDo = () => {
  return (
    <>
      <section className='promo-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              
            <Fade bottom>
              <SectionTitle
                title=' Collaborate with our dynamic team to bring your digital products to life'
                description='iGeeksNG Solution is the right plug if you have your business, product, and project requirement in place and need a technical partner to develop the requirements for users and business-friendly artifacts.'
                  centerAlign
              />
              </Fade>
            </div>
          </div>
                 
          <Fade bottom>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4 connected-app-single'>
                <div className='promo-icon mb-32'>
                  <i style={{color:"#ffb116"}} className='fa-solid fa-laptop-code fa-3x'></i>
                </div>

                 {/* Application development section */}
                <div className='promo-info'>
                  <h3 className='h5'>Application Development</h3>
                  <p className='mb-0'>
                  We develop software for today's businesses using current software engineering methodology, indigenous platforms & deep industry understanding.
                  </p>
                </div>
                <Link
                    to='/services'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            
                 {/* Infrastructure Engineering section */}
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4 connected-app-single'>
                <div className='promo-icon mb-32'>
                <i  style={{color:"#ffb116"}} className='fa-solid fa-gears fa-3x'></i>
                </div>
                <div className='promo-info'>
                <h3 className='h5'>Infrastructure Engineering and Maintenance</h3>
                  <p className='mb-0'>
                 Maintain continuous availability of your business by utilizing flexible digital infrastructure and services to create tailored experiences.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
                <Link
                    to='/services'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details <i className='far fa-arrow-right'></i>
                  </Link>
              </div>
              
            </div>
               
                 {/* Technical Expert section */}
            <div className='col-lg-4 col-md-6'>
              <div className='promo-single position-relative text-center bg-white custom-shadow rounded-custom p-5 mb-4 connected-app-single'>
                <div className='promo-icon mb-32'>
                <i style={{color:"#ffb116"}} className='fa-solid fa-laptop-code fa-3x'></i>
                </div>
                <div className='promo-info'>
                <h3 className='h5'>Technical Expert as a Service</h3>
                <p className='mb-0'>
                Need technical support with your architecture or processes? Our experts with over 15 years of experience will help your development team's challenges hands-on.
                  </p>
                </div>
                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
                <Link
                    to='/services'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    View Details<i className='far fa-arrow-right'></i>
                  </Link>
              </div>
            </div>
         
          </div>
       
          </Fade>
         
          <div className='customer-section pt-60'>
          <Fade bottom>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-8 col-12'>
                <p className='text-center mt-5 mb-0 h6'>
                  We've earned the Trust of our Incredible Clients:
                  </p>
                 
                </div>
               
              </div>
              
            </div>
            <Clients />
            </Fade>
          </div>
         
        </div>
        
      </section>
    </>
  );
};

export default WhatWeDo;
